/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

// Helpers
var doDeviceSpecific = function(mobile, desktop) {
  if( /Android|Phone|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent) ) {
    mobile();
  } else if (desktop) {
    desktop();
  }
};

var doResolutionSpecific = function (f, from, until) {
  var breakpoints = {
    mobile_portrait: 480,
    mobile_landscape: 544,
    tablet_portrait: 768,
    tablet_landscape: 992,
    wide: 1200
  };

  until = breakpoints[until];
  from = breakpoints[from];
  var trigger = false;
  if (from && until && (screen.width >= from) && (screen.width < until)) {
    trigger = true;
  } else if ((until) && (screen.width < until)) {
    trigger = true;
  } else if ((from) && (screen.width >= from)) {
    trigger = true;
  }

  if (trigger) { f(); }
};

// NOT IN USE
// var simulateClick = function (target) {
//   var event = new MouseEvent('click', {
//     'view': window,
//     'bubbles': true,
//     'cancelable': true
//   });
//   target.dispatchEvent(event);
// };

// Main
(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Sticky banner
        var timeout = null;
        var doStickyMenu = function() {
          // Only trigger for mobile portrait, reset in case of everything else
          // if (($(window).width() >= 768) || ($(window).width() < 544)) {
          doResolutionSpecific(function() {
            var current = $(window).scrollTop();
            var trigger = $("#banner-brand-logo").height();

            if (current >= trigger) {
              $("#banner-wrapper").addClass("banner-wrapper-sticky-mobile-portrait");
            } else if (current < trigger) {
              $("#banner-wrapper").removeClass("banner-wrapper-sticky-mobile-portrait");
            }
          }, 'mobile_landscape', 'tablet_portrait');

          doResolutionSpecific(function() {
            $("#banner-wrapper").removeClass("banner-wrapper-sticky-mobile-portrait");
          }, 'tablet_portrait');

          doResolutionSpecific(function() {
            $("#banner-wrapper").removeClass("banner-wrapper-sticky-mobile-portrait");
          }, null, 'mobile_portrait');
        };

        $(window).scroll(function () {
          if (!timeout) {
            timeout = setTimeout(function () {
              clearTimeout(timeout);
              timeout = null;
              doStickyMenu();
            }, 100);
          }
        });

        $(window).resize(function () { doStickyMenu(); });

        // Girls By Name Selector
        var girlsByNameSelector = $('#girls-by-name-selector');

        girlsByNameSelector.change(function (e) {
          window.location.href= $(e.currentTarget).find("option:selected")[0].value;
        });

        // Tabular sidebar navigation
        $('.tabular-sidebar > .tab-list > li').click(function (e) {
          e.preventDefault();
          $(this).children().tab('show');
        });

        // Prevent capture of Escort's top anchor click events
        // $('.escort > a').click(function (e) {
        //   e.preventDefault();
        // });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    },
    // Gallery page
    'page_template_gallery': {
      init: function () {
		  
		  $('.cef_escort_attributes_services').selectpicker({
              noneSelectedText: 'Select',
              width: 'css-width',
              hideDisabled: true
          });
		  
        /*
        Trigger extended-view toggle visibility
         */
        var single_toggle = $('.extended-view-toggle-single');
        var double_toggle = $('.extended-view-toggle-double');
        var triple_toggle = $('.extended-view-toggle-triple');

        // Always initialize the main toggle for mobiles
        doResolutionSpecific(function () {
          double_toggle.show();
        }, null, 'tablet_portrait');

        single_toggle.click(function () {
          single_toggle.hide();
          double_toggle.show();
          $('.main').toggleClass('main-extended');
        });

        double_toggle.click(function () {
          doResolutionSpecific(function () {
            double_toggle.hide();
            single_toggle.show();
          }, null, 'mobile_landscape');

          doResolutionSpecific(function () {
            double_toggle.hide();
            triple_toggle.show();
          }, 'mobile_landscape', null);

          $('.main').toggleClass('main-extended');
        });

        triple_toggle.click(function () {
          triple_toggle.hide();
          double_toggle.show();
          $('.main').toggleClass('main-extended');
        });

        // Reset all toggles to their default state based on the current resolution
        $(window).resize(function() {
          if (single_toggle.filter($(':visible')).length > 0) {
            single_toggle.hide();
            double_toggle.show();
          }
          else if (triple_toggle.filter($(':visible')).length > 0) {
            triple_toggle.hide();
            single_toggle.show();
          }
        });
      }
    },
    'page_template_gallery_v2': {
      init: function () {
		  
		  $('.cef_escort_attributes_services').selectpicker({
              noneSelectedText: 'Select',
              width: 'css-width',
              hideDisabled: true
          });
		  
        /*
        Trigger extended-view toggle visibility
         */
        var single_toggle = $('.extended-view-toggle-single');
        var double_toggle = $('.extended-view-toggle-double');
        var triple_toggle = $('.extended-view-toggle-triple');

        // Always initialize the main toggle for mobiles
        doResolutionSpecific(function () {
          double_toggle.show();
        }, null, 'tablet_portrait');

        single_toggle.click(function () {
          single_toggle.hide();
          double_toggle.show();
          $('.main').toggleClass('main-extended');
        });

        double_toggle.click(function () {
          doResolutionSpecific(function () {
            double_toggle.hide();
            single_toggle.show();
          }, null, 'mobile_landscape');

          doResolutionSpecific(function () {
            double_toggle.hide();
            triple_toggle.show();
          }, 'mobile_landscape', null);

          $('.main').toggleClass('main-extended');
        });

        triple_toggle.click(function () {
          triple_toggle.hide();
          double_toggle.show();
          $('.main').toggleClass('main-extended');
        });

        // Reset all toggles to their default state based on the current resolution
        $(window).resize(function() {
          if (single_toggle.filter($(':visible')).length > 0) {
            single_toggle.hide();
            double_toggle.show();
          }
          else if (triple_toggle.filter($(':visible')).length > 0) {
            triple_toggle.hide();
            single_toggle.show();
          }
        });
      }
    },
    // Escort's profile page
    'single_cef_escort': {
      init: function () {
        /*
         Prevent redirection when selecting booking form model
          */
        // $('select').click(function(e) {
        //   e.preventDefault();
        //   e.stopPropagation();
        // });

        /*
         Initialize `bootstrap-select`-based components
          */
        doDeviceSpecific(function () {}, function () {
          $('.outfit_select').selectpicker({
              noneSelectedText: 'Choose outfit',
              width: 'css-width',
              hideDisabled: true
          });
          $('.toys_select').selectpicker({
              noneSelectedText: 'Select toys',
              width: 'css-width',
              hideDisabled: true
          });
          $('#review-form_duration_select').selectpicker({
            noneSelectedText: 'Select duration',
            width: 'css-width',
            hideDisabled: true
          });
          $('#booking-form_type_select').selectpicker({
              noneSelectedText: 'Select type',
              width: 'css-width',
              hideDisabled: true
          });
          $('#booking-form_duration_select').selectpicker({
            noneSelectedText: 'Select duration',
            width: 'css-width',
            hideDisabled: true
          });
          // Patch container CSS class
          $('div.bootstrap-select').addClass('form-control');
        });

        /*
        Trigger Meeting Point input visibility
         */
        var meetingPointVisibilityDetection = function (e) {
          var addressElement = $(e.currentTarget).closest('.form-group-meeting-type').next('.form-group-meeting-point');
          if ($(e.currentTarget).children('option:checked').first().attr('value') === 'incall') {
            addressElement.removeClass('visible');
          } else {
            addressElement.addClass('visible');
          }
        };
        $('#booking-form_type_select').change(meetingPointVisibilityDetection);

        /*
         Initialize `datetimepicker`-based components
          */
        var options = {
          'showClose': true,
          // 'useCurrent': false
          // 'debug': true
        };
		  
		  var minDate = moment().add(180, 'minutes');
		  //console.log(minDate);
		  
        var date_options = {'format': 'YYYY-MM-DD', focusOnShow: false};
        var time_options = {'format': 'HH:mm', focusOnShow: false};
		var date_options_b = {'format': 'YYYY-MM-DD', minDate : minDate, focusOnShow: false};
        var time_options_b = {'format': 'HH:mm', minDate : minDate, focusOnShow: false};
        $.extend(date_options, options);
        $.extend(time_options, options);
		  $.extend(date_options_b, options);
        $.extend(time_options_b, options);

        var review_form_date_options  = {'widgetParent': $('#review-form_date_input_picker')};
        var review_form_time_options  = {'widgetParent': $('#review-form_time_input_picker')};
        var booking_form_date_options  = {'widgetParent': $('#booking-form_date_input_picker')};
        var booking_form_time_options  = {'widgetParent': $('#booking-form_time_input_picker')};
		  var booking_form_date_options_2  = {'widgetParent': $('#booking-form_date_input_picker_2')};
        var booking_form_time_options_2  = {'widgetParent': $('#booking-form_time_input_picker_2')};
        $.extend(review_form_date_options, date_options);
        $.extend(review_form_time_options, time_options);
        $.extend(booking_form_date_options, date_options_b);
        $.extend(booking_form_time_options, time_options_b);
		  $.extend(booking_form_date_options_2, date_options_b);
        $.extend(booking_form_time_options_2, time_options_b);
		  
//		  var currentTimestampBooking = Date.now();
//          var minDateBooking = new Date(currentTimestampBooking);
//		  var date_options_booking = {'minDate': minDateBooking};
//		  $.extend(booking_form_date_options, date_options_booking);

        // We try to use native input as much as possible
        doDeviceSpecific(function () {
          // Assign sensible limits only for mobile as the native controls are not used on anything else
//          var currentTimestamp = Date.now();
//          var minDate = new Date(currentTimestamp - 1000 * 60 * 60 * 24 * 30 * 6);
//          var maxDate = new Date(currentTimestamp);
//          var minYear = minDate.getFullYear();
//          var minMonth = minDate.getMonth() + 1;
//          var minDay = minDate.getDate();
//          var maxYear = maxDate.getFullYear();
//          var maxMonth = maxDate.getMonth() + 1;
//          var maxDay = maxDate.getDate();
//
//          $('#review-form_date_input')
//              .attr('min', minYear + '-' + minMonth + '-' + minDay)
//              .attr('max', maxYear + '-' + maxMonth + '-' + maxDay);
//          $('#booking-form_date_input')
//              .attr('min', minYear + '-' + minMonth + '-' + minDay)
//              .attr('max', maxYear + '-' + maxMonth + '-' + maxDay);
			$('#review-form_date_input').datetimepicker(review_form_date_options).attr('type', 'text');
          $('#review-form_time_input').datetimepicker(review_form_time_options).attr('type', 'text');
          $('#booking-form_date_input').datetimepicker(booking_form_date_options).attr('type', 'text');
          $('#booking-form_time_input').datetimepicker(booking_form_time_options).attr('type', 'text');
			$('#booking-form_date_input_2').datetimepicker(booking_form_date_options_2).attr('type', 'text');
          $('#booking-form_time_input_2').datetimepicker(booking_form_time_options_2).attr('type', 'text');
        }, function () {
          $('#review-form_date_input').datetimepicker(review_form_date_options).attr('type', 'text');
          $('#review-form_time_input').datetimepicker(review_form_time_options).attr('type', 'text');
          $('#booking-form_date_input').datetimepicker(booking_form_date_options).attr('type', 'text');
          $('#booking-form_time_input').datetimepicker(booking_form_time_options).attr('type', 'text');
			$('#booking-form_date_input_2').datetimepicker(booking_form_date_options_2).attr('type', 'text');
          $('#booking-form_time_input_2').datetimepicker(booking_form_time_options_2).attr('type', 'text');
        });
		  
		  $('#booking-form_date_input').on('dp.change', function(e){
			  var iscurrentDate = e.date.isSame(new Date(), "day");
				if(iscurrentDate) {
					$('#booking-form_time_input').data("DateTimePicker").minDate(minDate);
				} else {
					$('#booking-form_time_input').data("DateTimePicker").minDate('');
				}
		  });
		  
		  $('#booking-form_date_input_2').on('dp.change', function(e){
			  var iscurrentDate = e.date.isSame(new Date(), "day");
				if(iscurrentDate) {
					$('#booking-form_time_input_2').data("DateTimePicker").minDate(minDate);
				} else {
					$('#booking-form_time_input_2').data("DateTimePicker").minDate('');
				}
		  });

        /*
         Initialize `bootstrap-star-rating`-based components
          */
        options = {
          'containerClass': 'star-rating',
          'emptyStar': '',
          'filledStar': '',
          'stars': 5,
          'min': 0,
          'max': 5,
          'step': 1,
          'showClear': false,
          'showCaption': false
        };

        $('#review-form_looks_input').rating(options);
        $('#review-form_performance_input').rating(options);
        $('#review-form_attitdue_input').rating(options);

        /*
         Trigger DUO model selection
          */
        var toggleFormDUO = function (e) {
          e.preventDefault();
			$('.secondary-escort-booking').toggle();
			if ($(this).parent().hasClass('duo-notice-abandon')) {
				$('.secondary-escort-booking').html('');
			} else {
				$('.main-escort-booking .model_select').trigger('change');
			}
          var form = $(e.currentTarget).closest('.booking-form_selection');
          form.toggleClass('booking-form_selection-duo-active');
          form.toggleClass('booking-form_selection-duo-inactive');
          form.toggleClass('booking-form_selection-duo-no-selection');
          $(window).scrollTo(form.offset().top - $('#banner-wrapper').height(), 150);
        };
        $('.booking-form_primary_selection_duo_toggle_btn').click(toggleFormDUO);
        $('.model_select').change(function (e) {
          $(e.currentTarget).closest('.booking-form_selection').toggleClass('booking-form_selection-duo-no-selection');
        });

      }
    },

    // Employment form template
    'page_template_employment': {
      init: function () {
        /*
         Initialize `bootstrap-select`-based components
         */
        doDeviceSpecific(function () {}, function () {
          $('#employment-form_nationality_select').selectpicker({
            noneSelectedText: 'Nationality',
            width: 'css-width',
            size: 7,
            hideDisabled: true
          });
          $('#employment-form_age_select').selectpicker({
            noneSelectedText: 'Age',
            width: 'css-width',
            size: 7,
            hideDisabled: true
          });
          $('#employment-form-height_select').selectpicker({
            noneSelectedText: 'Height',
            width: 'css-width',
            size: 7,
            hideDisabled: true
          });
          $('#employment-form_dress_size_select').selectpicker({
            noneSelectedText: 'Dress size',
            width: 'css-width',
            size: 7,
            hideDisabled: true
          });
          $('#employment-form_breast_size_select').selectpicker({
            noneSelectedText: 'Cup size',
            width: 'css-width',
            size: 7,
            hideDisabled: true
          });
          $('#employment-form_hair_color_dropdown').selectpicker({
            noneSelectedText: 'Hair colour',
            width: 'css-width',
            size: 7,
            hideDisabled: true
          });
          $('#employment-form_eye_color_dropdown').selectpicker({
            noneSelectedText: 'Eye colour',
            width: 'css-width',
            size: 7,
            hideDisabled: true
          });
          $('#employment-form_language_dropdown').selectpicker({
            noneSelectedText: 'Spoken language',
            width: 'css-width',
            size: 7,
            hideDisabled: true
          });
          $('#employment-form_orientation_select').selectpicker({
            noneSelectedText: 'Sexual orientation',
            width: 'css-width',
            size: 7,
            hideDisabled: true
          });

          // Patch container CSS class
          $('div.bootstrap-select').addClass('form-control');
        });

        /*
        Initialize the list of photo inputs
         */
        var photo_inputs = $('#employment-form_photo_list > li input');

        // First element is always visible
        photo_inputs.first().parents('li').show();

        // Subsequent elements appear only if the preceding photo was selected
        photo_inputs.change(function (e) {
          $(e.currentTarget).parents('li').next().show();
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
